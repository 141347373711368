<template lang="pug">
    Dialog.dialogEditar(header="Editar Guia Eletronica" :visible.sync='$parent.dialogEditar' :modal='true')
        form(@submit.prevent='handleSubmit()')
            .ta-center(v-if='waiting')
                ProgressSpinner.spinner-waiting(strokeWidth='2')
            .p-grid.p-fluid.p-align-end(v-else)
                .p-col-6
                    label.form-label Cód. guia:
                    InputText(type='text' v-model='model.cd_transacao_financeira' :readonly='true')
                .p-col-6
                    label.form-label Cód. Interno:
                    InputText(type='text' v-model='model.cd_guia_eletronica_id' :readonly='true')
                .p-col-12
                    label.form-label Procedimento:
                    InputText(type='text' v-model='model.nm_procedimento_f' :readonly='true')
                .p-col-12.p-md-9
                    label.form-label Paciente:
                    InputText(type='text' v-model='model.nm_paciente' :readonly='true')
                .p-col-12.p-md-3.ta-center
                    label.form-label Situação:
                    p.mtd-badge.co1 {{ model.nm_situacao }}
                .p-col-12.p-md-6
                    label.form-label Estabelecimento:
                    InputText(type='text' v-model='model.nm_estabelecimento' :readonly='true')
                .p-col-12.p-md-6
                    label.form-label Especialidade:
                    InputText(type='text' v-model='model.nm_especialidade' :readonly='true')
                .p-col-12.p-md-7(v-if="model.ie_tipo_agenda == 'CO'")
                    label.form-label Especialista:
                    Dropdown(v-model='model.cd_especialista_id' :options='options.especialistas' :filter='true'
                        optionLabel='text' optionValue='value' dataKey='value' placeholder='Selecione')
                .p-col-6
                    label.form-label Situação:
                    Dropdown(v-model='model.ie_status' :options='options.ie_status'
                        optionLabel='text' optionValue='value' dataKey='value' placeholder='Selecione')

                .p-col-6(v-if='model.ie_status == 5 && usuarioNivel >=5')
                    label.form-label Data Execução:
                    Calendar( v-model='dt_execucao_f' dateFormat="dd/mm/yy" :locale="ptbr"
                                        :manualInput='true' :touchUI='windowInnerWidth < 576' )

                .p-col-6.p-md-3(v-if="model.ie_status == 4 && model.cd_guia_tipo != 4 && usuarioNivel >=5")
                    label.form-label Valor:
                        .p-inputgroup
                            span.p-inputgroup-addon R$
                            span
                                currency-input.input-adicionar.p-inputnumber-input.p-inputtext.p-component.p-filled(readonly
                                    v-model='model.nr_valortotal' locale='pt-BR' :auto-decimal-mode='true' :value-range='{ min: 0 }')
                .p-col-6.p-md-3(v-if="model.ie_status === 4 && model.cd_guia_tipo != 4 && usuarioNivel >=5")
                    label.form-label Repasse:
                        .p-inputgroup
                            span.p-inputgroup-addon R$
                            span
                                currency-input.input-adicionar.p-inputnumber-input.p-inputtext.p-component.p-filled(
                                    v-model='model.nr_valor_repasse' locale='pt-BR' :auto-decimal-mode='true' :value-range='{ min: 0 }')
                .p-col-12.p-md-6(v-if="model.ie_status === 5 && model.nm_usuario_execucao")
                    label.form-label Usuário que executou:
                    InputText(type='text' v-model='model.nm_usuario_execucao' :readonly='true')

                .p-col-12.mt-2(v-if="model.id")
                    .p-grid
                        .p-col-12.p-md-6
                            span.d-block.o-hidden <b>Usuário criação: </b> {{ model.nm_usuario_cri }}
                        .p-col-12.p-md-6
                            span <b>Data da criação: </b> {{ model.dt_criado_f }}
                        .p-col-12.p-md-6
                            span.d-block.o-hidden <b>Usuário edição: </b> {{ model.nm_usuario_edi }}
                        .p-col-12.p-md-6
                            span <b>Data da atualização: </b> {{ model.dt_atualizado_f }}

            .ta-center.mt-2
                ProgressSpinner(v-if="waitingSubmit" strokeWidth="6" style="width:29px;height:25px;margin:0")
                Button(v-else icon='jam jam-save' label='Salvar')

</template>

<style lang="scss">
    //.guia-eletronica {
        .dialogEditar {
            @media all and (min-width: 577px) {
                max-width: 640px;
                width: 100%;
            }
            .p-inputtext[disabled=disabled] {
                color: #000;
                font-weight: 600;
                background-color: #ddd;
            }
            .p-dialog-content {
                overflow-y: visible !important;
            }
            .mtd-badge {
                margin-top: 6px;
                font-size: 14px;
                padding: 6px 12px;
            }
            .p-progress-spinner {
                width: 80px;
                margin: 40px auto;
            }
            .spinner-submit {
                width: 20px;
                height: auto;
            }
        }
    //}
</style>

<script>
    import Dialog from 'primevue/dialog'
    import ProgressSpinner from 'primevue/progressspinner'
    import InputText from 'primevue/inputtext'
    import Dropdown from 'primevue/dropdown'
    import Button from 'primevue/button'
    import Calendar from 'primevue/calendar'

    //import moment from 'moment'
    import { GuiaEletronica, Especialista } from './../../middleware'
    import { pCalendarLocale_ptbr } from './../../utils'
    import moment from 'moment'
    export default {
        components: { Calendar, Dialog, InputText, ProgressSpinner, Dropdown, Button },
        created(){
            let usuario = JSON.parse(localStorage.getItem('usuario'))
            this.usuarioNivel = usuario.nivel
        },
        watch: {
            '$parent.selectedRow': function (val) {
                if (this.$parent.dialogEditar) {

                    this.model = val
                    this.model.nm_procedimento_f = `${ this.model.cd_procedimento } - ${ this.model.nm_procedimento }`
                    this.model.nr_valortotal = val.valor_total
                    this.model.nr_valor_repasse = val.valor_repasse
                    this.model.nm_usuario_execucao = val.nm_usuario_execucao
                    this.dt_execucao_f = null
                    this.model.dt_criado_f = moment(val.dt_criado).format('DD/MM/YYYY HH:mm')
                    if (val.dt_atualizado) {
                        this.model.dt_atualizado_f = moment(val.dt_atualizado).format('DD/MM/YYYY HH:mm')
                    }
                    if(this.model.dt_execucao){
                        this.dt_execucao_f = this.model.dt_execucao
                    }

                    if( this.model.ie_tipo_agenda == 'CO' )
                        this.getEspecialistas()
                    else
                        this.waiting = false

                    if( this.usuarioNivel <= 3 ){
                        this.options.ie_status = [
                            { value: null, text: '- SELECIONE -' },
                            { value: 4, text: 'Agendado' },
                            { value: 5, text: 'Executado' },
                        ]
                        if( val.ie_status == 5 ){
                            this.options.ie_status = [
                                { value: null, text: '- SELECIONE -' },
                                { value: 4, text: 'Agendado' },
                                { value: 5, text: 'Executado' },
                            ]
                        }
                    }
                    
                }
            }
        },
        data () {
            return {
                usuarioNivel:0,
                ptbr: pCalendarLocale_ptbr,
                windowInnerWidth: window.innerWidth,
                model: {},
                dt_execucao_f:{},
                dataSend: {
                    cd_especialista:null,
                    cd_guia_situacao:null,
                },
                options: {
                    especialistas: [],
                    ie_status: [
                        { value: null, text: '- SELECIONE -' },
                        { value: 4, text: 'Agendado' },
                        { value: 5, text: 'Executado' },
                        { value: 7, text: 'Cancelado' }
                    ]
                },
                waiting: false,
                waitingSubmit: false
            }
        },
        methods: {
            getEspecialistas () {

                this.waiting = true
                this.options.especialistas = []

                Especialista.findAll({ cd_estabelecimento: this.model.cd_estabelecimento }).then(response => {
                    if (response.status == 200)
                        response.data.forEach(especialista => {
                            this.options.especialistas.push({ value: especialista.id, text: especialista.nm_especialista })
                        })
                    this.waiting = false
                })
            },
            handleSubmit () {
                if (this.model.nr_valortotal < this.model.nr_valor_repasse) {
                    this.$toast.error("O valor repasse não pode ser maior que o valor total.")
                    return
                }

                this.dataSend.id = this.model.cd_guia_eletronica_id
                this.dataSend.cd_guia_situacao = this.model.ie_status
                this.dataSend.nr_valor_repasse = this.model.nr_valor_repasse
                this.dataSend.cd_especialista = this.model.cd_especialista_id
                if(this.dataSend.cd_guia_situacao == 5)
                    this.dataSend.dt_execucao = moment(this.dt_execucao_f, 'DD/MM/YYYY').format('YYYY-MM-DD')
                console.log(this.dataSend)
                this.waitingSubmit = true
                GuiaEletronica.save(this.dataSend).then(response => {
                    this.waitingSubmit = false
                    if (([200, 201]).includes(response.status)) {
                        this.$toast.success('Guia atualizada!', { duration: 3000 })
                        if (response.data.message != ''){
                            this.$toast.info(response.data.message, { duration: 10000 })
                        }
                        this.$parent.dialogEditar = false
                        this.$parent.applyFilters()
                    } else if (response.status == 400) {
                        if (response.data.detail) {
                            if (typeof response.data.detail == 'string')
                                this.$toast.error(response.data.detail, { duration: 3000 })
                            else response.data.detail.forEach(msg => this.$toast.error(msg, { duration: 3000 }))
                        }
                    }
                })
                
            }
        }
    }
</script>