<template lang="pug">
    .main-wrapper.guia-listar
        header.main-wrapper-header
            .p-grid.p-align-center
                .p-col-12.ta-right
                    h1.text-header.text-secondary MedClub / <b>Guias Eletrônicas</b>

        Editar

        TabView(@tab-change='onTabChange')
            TabPanel(header='Consultas' v-bind:cdGuiaTipo="2" :active="filters.cd_guia_tipo == 2" :disabled='waiting')
            TabPanel(header='Exames' v-bind:cdGuiaTipo="3" :active="filters.cd_guia_tipo == 3" :disabled='waiting')
            TabPanel(header='Cirurgias' v-bind:cdGuiaTipo="4" :active="filters.cd_guia_tipo == 4" :disabled='waiting')

        Panel.mb-2(header='Filtros' :toggleable='true' :collapsed='windowInnerWidth < 576')
            .p-grid.p-fluid.p-align-end

                .p-col-12.p-md-4
                    label.form-label Nome do paciente:
                    .p-inputgroup
                        InputText( @keyup.enter.native='applyFilters()' v-model='filters.nm_paciente'
                            @keyup='checkEmptyField("nm_paciente")' )
                        Button(icon='jam jam-search' @click='applyFilters()')

                .p-col-12.p-md-4
                    label.form-label Especialista/Profissional:
                    .p-inputgroup
                        InputText( @keyup.enter.native='applyFilters()' v-model='filters.nm_especialista'
                            @keyup='checkEmptyField("nm_especialista")' )
                        Button(icon='jam jam-search' @click='applyFilters()')

                .p-col-12.p-md-4
                    label.form-label Procedimento:
                    .p-inputgroup
                        InputText( @keyup.enter.native='applyFilters()' v-model='filters.nm_procedimento'
                            @keyup='checkEmptyField("nm_procedimento")' )
                        Button(icon='jam jam-search' @click='applyFilters()')

                .p-col-12.p-md-2
                    label.form-label Código da guia:
                    .p-inputgroup
                        InputText( @keyup.enter.native='applyFilters()' v-model='filters.cd_transacao_financeira'
                            @keyup='checkEmptyField("cd_transacao_financeira")' )
                        Button(icon='jam jam-search' @click='applyFilters()')

                .p-col-12.p-md-2(v-if='filters.cd_guia_tipo == 2')
                    label.form-label Tipo:
                    Dropdown(v-model='filters.ie_retorno' :options='options.ie_tipo_consulta'
                        dataKey='value' optionLabel='text' optionValue='value' placeholder='TODAS' @change='applyFilters()' )

                .p-col-12.p-md-2
                    label.form-label Situação:
                    Dropdown( v-model='filters.ie_status' :options='options.ie_status'
                        dataKey='value' optionLabel='text' optionValue='value' placeholder='TODAS' @change='applyFilters()' )

                .p-col-12.p-md-2(v-if='filters.cd_guia_tipo == 3')
                    label.form-label Ordem de serviço:
                    .p-inputgroup
                        InputText( @keyup.enter.native='applyFilters()' v-model='filters.cd_os_shift'
                            @keyup='checkEmptyField("cd_ordem_servico_shift")' )
                        Button(icon='jam jam-search' @click='applyFilters()')

                .p-col-6.p-md-2
                    label.form-label Data inicial:
                    .p-inputgroup
                        Calendar( v-model='dt_inicial' dateFormat="dd/mm/yy" :locale="ptbr" :maxDate='dt_final'
                            :manualInput='false' :touchUI='windowInnerWidth < 576' @input='applyFilters()' )
                        Button.p-button-danger(icon='jam jam-rubber' v-tooltip.top="'Limpar'" @click='dt_inicial = null; applyFilters()')

                .p-col-6.p-md-2
                    label.form-label Data final:
                    .p-inputgroup
                        Calendar( v-model='dt_final' dateFormat="dd/mm/yy" :locale="ptbr" :minDate='dt_inicial'
                            :manualInput='false' :touchUI='windowInnerWidth < 576' @input='applyFilters()' )
                        Button.p-button-danger(icon='jam jam-rubber' v-tooltip.top="'Limpar'" @click='dt_final = null; applyFilters()')

                .p-col-6.p-md-2
                    label.form-label Checkup:
                    //Checkbox(v-model="filters.select_checkup" :binary="true")
                    Checkbox(v-model="filters.select_checkup" value=1 name='checkup' @input='applyFilters()')

                .p-col-12(:class="filters.cd_guia_tipo == 2 ? 'p-md-2' : 'p-md-4'")
                    Button(label='Limpar filtros' icon='jam jam-rubber' @click='clearFilters()')

        ProgressBar(v-if='waiting' mode="indeterminate")
        div(v-else-if='list.length == 0')
            p.ta-center.text-secondary(style='margin-top: 40px;') Nenhum registro encontrado.

        div(v-else)
            DataView.my-2.dataview(:value="list" layout="grid")
                template(#grid="props")
                    .p-col-12.p-md-3(style="padding: .5em")
                        Panel.panel-list.ta-center(:header="props.data.nm_paciente_f")
                            .ta-left
                                p(v-if='props.data.cd_transacao_financeira') <b>Código da guia:</b> {{ props.data.cd_transacao_financeira }}
                                p <b>Nome do paciente:</b> {{ props.data.nm_paciente }}
                                p(v-if='filters.cd_guia_tipo != 4') <b>Data:</b> {{ props.data.dt_agenda }}
                                p(v-if='filters.cd_guia_tipo != 4') <b>Horário:</b> {{ props.data.hr_agenda_f }}
                                p(v-else) <b>Dt. Validação:</b> {{ props.data.dt_validacao }}
                                p <b>Checkup: </b>
                                p <b>Situação: </b>
                                    span.mtd-badge(:class="{ co3: props.data.nm_situacao == 'EMITIDA', co5: props.data.ie_status == 'EXECUTADA', co6: props.data.ie_status == 'CANCELADA' }") {{ props.data.nm_situacao }}
                                p(v-if="filters.cd_guia_tipo == 2") <b>Especialidade:</b> {{ props.data.ds_especialidade }}
                                p(v-if="filters.cd_guia_tipo == 2") <b>Especialidade:</b> {{ props.data.ds_especialidade }}
                                p(v-if="filters.cd_guia_tipo == 2") <b>Especialista:</b> {{ props.data.ds_especialista }}
                                p(v-if="filters.cd_guia_tipo == 3") <b>Procedimento:</b> {{ props.data.nm_procedimento }}
                            .ta-right.mt-3
                                Button.p-button-info(label='Visualizar' @click='selectedRow=props.data; dialogVisualizar = true')
            Paginator.dataview(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")

            Panel.datatable.responsive(header='Lista de Guias')
                DataTable(:value="list" @sort='onSort')
                    // :sortable='true'
                    Column(headerStyle='width: 8%;' bodyStyle='text-align: center;' field='cd_guia_procedimentos' header='Cód. guia')
                    Column(v-if='filters.cd_guia_tipo == 3' headerStyle='width: 8%;' bodyStyle='text-align: center;' field='cd_ordem_servico_shift' header='Ordem de serviço')
                    Column(headerStyle='width: 15%;' field='nm_paciente'  bodyStyle='word-break: break-all;'  header='Nome do paciente')
                    Column(v-if='filters.cd_guia_tipo != 4' headerStyle='width: 10%;' bodyStyle='text-align: center;' field='dt_agenda' header='Data')
                    Column(v-if='filters.cd_guia_tipo != 4' headerStyle='width: 8%;' bodyStyle='text-align: center;' field='hr_agenda_f' header='Horário')
                    Column(headerStyle='width: 8%;' bodyStyle='padding: 0;')
                        template(#header) Checkup
                        template(#body='props')
                            .d-block(v-if='props.data.cd_checkup')
                                .cell(class="ex")  Sim
                            .d-block(v-else)
                                .cell(class="")  Não
                    Column(headerStyle='width: 8%;' bodyStyle='padding: 0; height: 100%')
                        template(#header) Situação
                        template(#body='props')
                            .cell(:class="{ \
                                ex: props.data.cd_guia_situacao === 5, \
                                a: props.data.cd_guia_situacao === 4, \
                                c: props.data.cd_guia_situacao === 7, \
                                f: props.data.cd_guia_situacao === 6, \
                                p: props.data.cd_guia_situacao === 8 \
                            }")
                                div
                                    span {{ props.data.nm_situacao }} <br>
                                    span(v-if='[6,8].includes(props.data.cd_guia_situacao)') <b>Lote:</b> {{ props.data.cd_pagamento }}
                    Column(v-if='filters.cd_guia_tipo == 2' headerStyle='width: 8%;' bodyStyle='padding: 0;' header='Tipo')
                        template(#body='props')
                            .cell(v-if='props.data.ie_retorno') Retorno
                            .cell(v-else) Consulta
                    Column(v-if="filters.cd_guia_tipo == 4" headerStyle='width: 10%;' bodyStyle='text-align: center;' field='dt_validacao' header='Dt. Validação')
                    Column(headerStyle='width: 10%;' bodyStyle='text-align: center;' field='dt_execucao' header='Dt. Execução')
                    Column(v-if="[2, 4].includes(filters.cd_guia_tipo)" headerStyle='width: 14%;' field='nm_especialista' header='Especialista')
                    Column(v-if="filters.cd_guia_tipo == 2" headerStyle='width: 14%;' field='nm_especialidade' header='Procedimento')
                    Column(v-if="[3, 4].includes(filters.cd_guia_tipo)" headerStyle='width: 20%;' field='nm_procedimento' header='Procedimento')
                    Column(headerStyle='width: 10%; text-align: center')
                        template(#header) Ações
                        template(#body='props')
                            .ta-center
                                Button.p-button-raised.p-button-rounded.mr-1(
                                    v-if="filters.cd_guia_tipo != 4 && ((usuarioNivel >= 3 && ([4,5]).includes(props.data.cd_guia_situacao)) || usuarioNivel >= 5)"
                                    v-tooltip.top="'Editar'"
                                    icon="jam jam-write"
                                    @click='selectedRow = props.data; dialogEditar = true'
                                )
                                Button.p-button-raised.p-button-rounded.mr-1(
                                    v-if="props.data.cd_horario_agendamento"
                                    v-tooltip.top="'Imprimir'"
                                    icon="jam jam-document"
                                    @click='imprimirGuia(props.data.cd_horario_agendamento)'
                                )
                                Button.p-button-raised.p-button-rounded.mr-1(
                                    v-if="props.data.cd_ordem_servico_shift"
                                    v-tooltip.top="'Consultar Laudo'"
                                    icon="jam jam-files"
                                    @click='consultarLaudoShift(props.data.id)'
                                )
                Paginator(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")

</template>

<style lang="scss">
    .guia-listar {
        .dataview {
            @media all and (min-width: 577px) {
                display: none;
            }
        }
        .datatable {
            @media all and (max-width: 576px) {
                display: none;
            }
            .cell {
                padding: 0px 0;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100px;
                text-align: center;
                &.ex  {  background-color: #83BD7A; }
                &.c  {  background-color: #fc9595; }
                &.a  { background-color: #E8E87D; }
                &.f { background-color: #9EB5EE;}
                &.p { background-color: #F0AC47;}
            }
        }
        .p-tabview-panels {
            padding: 0 !important;
            border: none !important;
        }
        .p-tabview {
            padding-bottom: 0;
        }
        .p-dropdown-filter-container {
            width: 100%;
        }
        .btn-criarAgendamento {
            font-size: 16px; font-weight: 700
        }
    }
</style>

<script>
    import DataView from 'primevue/dataview'
    import Column from 'primevue/column'
    import DataTable from 'primevue/datatable'
    import Paginator from 'primevue/paginator'
    import Panel from 'primevue/panel'
    import Calendar from 'primevue/calendar'
    import ProgressBar from 'primevue/progressbar'
    import InputMask from 'primevue/inputmask'
    import Button from 'primevue/button'
    import Dropdown from 'primevue/dropdown'
    import InputText from 'primevue/inputtext'
    import Checkbox from 'primevue/checkbox'
    import Dialog from 'primevue/dialog'
    import TabView from 'primevue/tabview'
    import TabPanel from 'primevue/tabpanel'
    import Tooltip from 'primevue/tooltip'

    import Editar from './Editar'

    import { GuiaEletronica, Agendamento } from './../../middleware'
    import wsConfigs from './../../middleware/configs'

    import moment from 'moment'
    import { pCalendarLocale_ptbr, fixTelefoneFormat } from './../../utils'

    export default {
        components: { DataView, Panel, ProgressBar, Calendar, InputMask, TabView, Tooltip, Paginator,
            Button, Dropdown, InputText, DataTable, Column, Dialog, TabPanel, Editar, Checkbox },
        directives: { tooltip: Tooltip },
        computed: {
            dt_inicial: {
                get() {
                    if (this.filters.cd_guia_tipo == 4) return this.filters.dt_validacao_inicial
                    return this.filters.dt_agenda_inicial
                },
                set(val) {
                    if (this.filters.cd_guia_tipo == 4) this.filters.dt_validacao_inicial = val
                    else this.filters.dt_agenda_inicial = val
                }
            },
            dt_final: {
                get() {
                    if (this.filters.cd_guia_tipo == 4) return this.filters.dt_validacao_final
                    return this.filters.dt_agenda_final
                },
                set(val) {
                    if (this.filters.cd_guia_tipo == 4) this.filters.dt_validacao_final = val
                    else this.filters.dt_agenda_final = val
                }
            }
        },
        created () {

            this.usuarioNivel = JSON.parse(localStorage.getItem('usuario')).nivel
            this.applyFilters()

        },
        data () {
            return {
                list: [],
                usuarioNivel: 0,
                ptbr: pCalendarLocale_ptbr,
                windowInnerWidth: window.innerWidth,
                waiting: false,
                selectedRow: {},
                dialogVisualizar: false,
                dialogEditar: false,
                filters: {
                    cd_guia_tipo: this.$route.query.tb || 2,
                    cd_transacao_financeira: null,
                    dt_agenda_inicial: null,
                    dt_agenda_final: null,
                    dt_validacao_inicial: null,
                    dt_validacao_final: null,
                    ie_status: null,
                    nm_paciente: null,
                    nm_especialista: null,
                    nm_procedimento: null,
                    ie_retorno: null,
                    select_checkup: 0,
                    cd_os_shift: null
                },
                options: {
                    ie_status: [
                        { value: null, text: '- TODAS -' },
                        { value: 4, text: 'Emitida' },
                        { value: 5, text: 'Executado' },
                        { value: 7, text: 'Cancelado' },
                        { value: 8, text: 'Processando' },
                        { value: 6, text: 'Faturada' },
                        { value: 10, text: 'Aguardando Resultado'}
                    ],
                    ie_tipo_consulta: [
                        { value: null, text: '- TODOS -' },
                        { value: true, text: 'Retorno' },
                        { value: false, text: 'Consulta' }
                    ]

                },
                paginator: {
                    page: this.$route.query.pg ? this.$route.query.pg : 1,
                    per_page: wsConfigs.paginator_perPage,
                    count: 0
                },
                order: {
                    field: '-dt_agenda',
                    sortOrder: 1
                }
            }
        },
        methods: {
            applyFilters () {
                let params = { paginacao: true, page: this.paginator.page, per_page: this.paginator.per_page }
                params.order = `${ this.order.sortOrder == -1 ? '-' : '' }${ this.order.field }`

                if (this.$route.query.tb != this.filters.cd_guia_tipo || this.$route.query.pg != this.paginator.page)
                    this.$router.replace( { query: { tb: this.filters.cd_guia_tipo, pg: this.paginator.page } } )

                Object.keys(this.filters).forEach((key) => {
                    if (this.filters[key]) {
                        if (key.substr(0, 3) == 'dt_')
                            params[key] = moment(this.filters[key]).format('YYYY-MM-DD')
                        else params[key] = this.filters[key]
                    }
                })

                this.getList(params)
            },
            onPage (ev) {
                this.paginator.page = ev.page + 1
                this.applyFilters()
            },
            onSort () {
                this.order.sortOrder = -this.order.sortOrder
                this.applyFilters()
            },
            onTabChange(ev) {
                this.filters.cd_guia_tipo = ev.tab.$attrs.cdGuiaTipo
                this.clearFilters()
            },
            checkEmptyField (field) {
                if (! this.filters[field].length) this.applyFilters()
            },
            clearFilters () {
                this.paginator.page = 1
                Object.keys(this.filters).forEach(k => { if (k !== 'cd_guia_tipo') this.filters[k] = null })
                this.applyFilters()
            },
            getList (params) {
                this.waiting = true
                GuiaEletronica.findAll(params).then(response => {
                    if (response.status == 200) {

                        this.paginator.count = response.data.count

                        response.data.results.forEach(entry => {

                            entry.dt_agenda = (entry.dt_agenda) ? moment(entry.dt_agenda).format('DD/MM/YYYY') : ""
                            entry.dt_execucao = (entry.dt_execucao) ? moment(entry.dt_execucao).format('DD/MM/YYYY') : ""
                            entry.dt_validacao = (entry.dt_validacao) ? moment(entry.dt_validacao).format('DD/MM/YYYY') : ""
                            entry.nm_paciente_f = (entry.nm_paciente == null) ? entry.nm_paciente : entry.nm_paciente.split(" ")[0]
                            if (entry.hr_agenda) {
                                let aux = entry.hr_agenda.split(':')
                                entry.hr_agenda_f = `${ aux[0] }:${ aux[1] }`
                            }
                            if (entry.nr_telefone_paciente) {
                                entry.nr_telefone_paciente_f = fixTelefoneFormat(entry.nr_telefone_paciente)
                            }
                        })
                        this.list = response.data.results
                    }
                    this.waiting = false
                })
            },
            imprimirGuia (id) {
                this.waitingImprimirGuia = id
                this.waiting = true
                Agendamento.imprimirGuia(id).then(response => {
                    this.waitingImprimirGuia = 0
                    if (response.status == 200) {
						if (response.data.detail) {
                            this.$toast.error(response.data.detail, { duration: 3000 })
                        } else {
                            let blob = new Blob([response.data], { type: 'application/pdf; charset=utf-8' })
                            var fileURL = URL.createObjectURL(blob);
                            let a = window.open(fileURL);
                            a.print()
                        }
                    }
                })
			},
            consultarLaudoShift(id){
                this.waiting = true
                GuiaEletronica.consultarLaudo(id).then(response => {
                    if (response.status == 200) {
						if (response.data.detail) {
                            this.$toast.error(response.data.detail, { duration: 3000 })
                        } else {
                            let a = window.open(response.data[0].resultado);
                            setTimeout(() => {
                                a.close()
                            }, "1000");

                            this.$toast.success('O PDF do resultado está sendo baixado.')
                        }
                    }else{
                        if (response.data.detail) {
                            this.$toast.error(response.data.detail, { duration: 3000 })
                        }
                    }
                    this.waiting = false
                })
            }
        }
    }
</script>
